import React from 'react';
import { Layout } from 'src/components/Layout';
import styles from './styles.module.css';
import { Spinner } from 'src/components/Spinner';
import { KeystoneUser } from 'src/helpers/UserManager';
import { getProfilePage, profileDetailsComponentDidMount } from 'src/components/PageBuilder';

interface State {
	loading: boolean;
	success: boolean;
	errors: any;

	// Database fields
	user?: KeystoneUser;

	// Page fields
	exclusionList?: string[];
	url?: string;
}

export default class ProfileDetails extends React.Component<State> {
	state: State = {
		loading: true,
		success: false,
		errors: {},
	};

	async componentDidMount() {
		await profileDetailsComponentDidMount(this);
	}

	render() {
		const { loading } = this.state;
		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? <div className={styles.tab}>{getProfilePage(this)}</div> : <Spinner />}
				</div>
			</Layout>
		);
	}
}
